.App {
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
a {
  text-decoration: none;
}
.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width:  3px;
  height: 3px;
}

::-webkit-scrollbar-button {
  background-color: #666;
}

::-webkit-scrollbar-track {
  background-color: #999;
}

::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  height:           50px;
  background-color: #666;
  border-radius:    3px;
}

::-webkit-scrollbar-corner {
  background-color: #999;
}

::-webkit-resizer {
  background-color: #666;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
